import React, { FunctionComponent } from "react"
import { StaticImage } from "gatsby-plugin-image"
import { GatsbyImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import OnScreen from "../components/OnScreen"
import Button from "../components/Button"
import StartButton from "../components/StartButton"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import Particles from "react-tsparticles"

const SuccessHabits = ({ data }) => {
  const newData = data.allContentfulSuccessHabits.edges[0].node

  return (
    <Layout absoluteHeader>
      <SEO
        description="Find out how different Healthy Habits Examples are the key to leveling up in life and how to combine them with science-backed gamification methods to propel your momentum."
        title="Healthy Habits Examples"
        link="https://mooremomentum.com/healthy-habits-examples/"
      />
      <div className="app-hero-bg fixed inset-0" style={{ zIndex: -1 }}>
        <Particles
          canvasClassName="app-particles"
          options={{
            fpsLimit: 60,
            interactivity: {
              events: {
                onClick: {
                  enable: true,
                  mode: "push",
                },
                onHover: {
                  enable: true,
                  mode: "bubble",
                },
                resize: true,
              },
              modes: {
                bubble: {
                  distance: 200,
                  duration: 2,
                  opacity: 0.8,
                  size: 12,
                },
                push: {
                  quantity: 4,
                },
              },
            },
            particles: {
              color: {
                value: "rgba(255, 255, 255, 0.20)",
              },
              collisions: {
                enable: true,
              },
              move: {
                direction: "none",
                enable: true,
                outMode: "bounce",
                random: false,
                speed: 1,
                straight: false,
              },
              number: {
                density: {
                  enable: true,
                  value_area: 800,
                },
                value: 80,
              },
              opacity: {
                value: 0.5,
              },
              shape: {
                type: "circle",
              },
              size: {
                random: true,
                value: 5,
              },
            },
            detectRetina: true,
          }}
        />
      </div>
      <div className="overflow-hidden">
        <div className=" pt-48 pb-20 sm:pt-40 xl:pt-48">
          <div className="container mx-auto px-4">
            <OnScreen
              className="text-center text-white"
              classToAdd="scale-in-ver-center"
            >
              <h1 className="font-Orbitron mb-8 text-shadow text-3xl md:text-4xl">
                {newData.headline}
              </h1>
            </OnScreen>
          </div>
        </div>

        <div className=" mb-16 mx-auto px-4  xl:container lg:mb-24 xl:text-left space-y-8">
          {/* <hr className="bg-blue border-none h-0.5 my-12 xl:bg-transparent" /> */}

          <div className="">
            <div className="text-link text-white space-y-8">
              <OnScreen classToAdd="slide-in-left space-y-8">
                {renderRichText(newData.firstSection, {
                  renderMark: {
                    [MARKS.BOLD]: text => (
                      <span className="font-bold">{text}</span>
                    ),
                    [MARKS.ITALIC]: text => <em>{text}</em>,
                  },
                  renderNode: {
                    [BLOCKS.PARAGRAPH]: (node, children) => (
                      <p className="">{children}</p>
                    ),
                    [BLOCKS.HEADING_1]: (node, children) => (
                      <h1 className="font-Orbitron text-4xl">{children}</h1>
                    ),
                    [BLOCKS.HEADING_2]: (node, children) => (
                      <h2 className="font-Orbitron text-3xl">{children}</h2>
                    ),
                    [BLOCKS.HEADING_3]: (node, children) => (
                      <h3 className="font-Orbitron text-xl">{children}</h3>
                    ),
                    [BLOCKS.EMBEDDED_ASSET]: node => {
                      if (!node.data.target) {
                        return
                      }
                      // console.log("Imagess",node.data.target.gatsbyImageData)
                      const { gatsbyImageData } = node.data.target
                      if (!gatsbyImageData) {
                        // asset is not an image
                        return null
                      }
                      return (
                        <figure className="flex flex-wrap justify-center items-center">
                          <GatsbyImage
                            class={
                              node.data.target.file &&
                              node.data.target.file.fileName &&
                              node.data.target.file.fileName
                                .toLowerCase()
                                .endsWith(".gif")
                                ? ""
                                : "border-8 border-coral"
                            }
                            image={gatsbyImageData}
                            alt={node.data.target.title}
                            style={{ maxWidth: "750px" }}
                            objectFit="contain"
                          />
                        </figure>
                      )
                    },
                  },
                })}
              </OnScreen>
            </div>
          </div>

          {/* <hr className="bg-blue border-none h-0.5 my-12 xl:bg-transparent" /> */}

          <div className="text-link text-white space-y-8">
            <div className="space-y-8">
              {renderRichText(newData.secondSection, {
                renderMark: {
                  [MARKS.BOLD]: text => (
                    <span className="font-bold">{text}</span>
                  ),
                  [MARKS.ITALIC]: text => <em>{text}</em>,
                },
                renderNode: {
                  [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
                  [BLOCKS.HEADING_1]: (node, children) => (
                    <h1 className="font-Orbitron text-4xl">{children}</h1>
                  ),
                  [BLOCKS.HEADING_2]: (node, children) => (
                    <h2 className="font-Orbitron text-3xl">{children}</h2>
                  ),
                  [BLOCKS.HEADING_3]: (node, children) => (
                    <h3 className="font-Orbitron text-xl">{children}</h3>
                  ),
                  [BLOCKS.EMBEDDED_ASSET]: node => {
                    if (!node.data.target) {
                      return
                    }
                    // console.log("Imagess",node.data.target.gatsbyImageData)
                    const { gatsbyImageData } = node.data.target
                    if (!gatsbyImageData) {
                      // asset is not an image
                      return null
                    }
                    return (
                      <figure className="flex flex-wrap justify-center items-center">
                        <GatsbyImage
                          class={
                            node.data.target.file &&
                            node.data.target.file.fileName &&
                            node.data.target.file.fileName
                              .toLowerCase()
                              .endsWith(".gif")
                              ? ""
                              : "border-8 border-coral"
                          }
                          image={gatsbyImageData}
                          alt={node.data.target.title}
                          style={{ maxWidth: "750px" }}
                          objectFit="contain"
                        />
                      </figure>
                    )
                  },
                },
              })}
            </div>
          </div>

          {/* <hr className="bg-blue border-none h-0.5 my-12 xl:bg-transparent" /> */}

          <div className="space-y-8 text-link text-center w-full sm:flex-1 sm:ml-8 sm:text-left">
            <OnScreen classToAdd="slide-in-left space-y-8 text-white">
              {renderRichText(newData.thirdSection, {
                renderMark: {
                  [MARKS.BOLD]: text => (
                    <span className="font-bold">{text}</span>
                  ),
                  [MARKS.ITALIC]: text => <em>{text}</em>,
                },
                renderNode: {
                  [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
                  [BLOCKS.HEADING_1]: (node, children) => (
                    <h1 className="font-Orbitron text-4xl">{children}</h1>
                  ),
                  [BLOCKS.HEADING_2]: (node, children) => (
                    <h2 className="font-Orbitron text-3xl">{children}</h2>
                  ),
                  [BLOCKS.HEADING_3]: (node, children) => (
                    <h3 className="font-Orbitron text-xl">{children}</h3>
                  ),
                  [BLOCKS.EMBEDDED_ASSET]: node => {
                    if (!node.data.target) {
                      return
                    }
                    // console.log("Imagess",node.data.target.gatsbyImageData)
                    const { gatsbyImageData } = node.data.target
                    if (!gatsbyImageData) {
                      // asset is not an image
                      return null
                    }
                    return (
                      <figure className="flex flex-wrap justify-center items-center">
                        {node.data.target.file.fileName ===
                        "button press start to begin.gif" ? (
                          <a href="/core-values-quiz/" target="_blank">
                            <GatsbyImage
                              image={gatsbyImageData}
                              alt={node.data.target.title}
                            />
                          </a>
                        ) : (
                          <GatsbyImage
                            class={
                              node.data.target.file &&
                              node.data.target.file.fileName &&
                              node.data.target.file.fileName
                                .toLowerCase()
                                .endsWith(".gif")
                                ? ""
                                : "border-8 border-coral"
                            }
                            image={gatsbyImageData}
                            alt={node.data.target.title}
                            style={{ maxWidth: "750px" }}
                            objectFit="contain"
                          />
                        )}
                      </figure>
                    )
                  },
                },
              })}
            </OnScreen>
          </div>
        </div>

        {/* <OnScreen className="px-4" classToAdd="slide-in-left">
          <div className="bg-blue max-w-6xl mx-auto px-4 py-8 rounded-xl shadow-lg text-center text-white">
            <h2 className="font-Orbitron mb-8 text-2xl lg:text-4xl">
              How Happy are You?
            </h2>

            <p className="mx-auto md:w-1/2">
              How are your current habits slowing or increasing your momentum in
              all five cores? Take the Happiness Quiz!
            </p>

            <div className="mt-8">
              <StartButton className="bg-white py-3 px-12 rounded-full text-blue" />
            </div>
          </div>
        </OnScreen> */}

        <div className="px-4">
          <OnScreen
            className="bg-coral max-w-6xl mx-auto my-12 px-4 py-8 rounded-xl shadow-lg sm:my-16"
            classToAdd="slide-in-right"
          >
            <div className="mx-auto space-y-8 text-white text-center text-lg sm:text-lg md:w-2/3">
              {renderRichText(newData.mission, {
                renderMark: {
                  [MARKS.BOLD]: text => (
                    <span className="font-bold">{text}</span>
                  ),
                  [MARKS.ITALIC]: text => <em>{text}</em>,
                },
                renderNode: {
                  [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
                  [BLOCKS.HEADING_1]: (node, children) => (
                    <h1 className="font-Orbitron text-4xl">{children}</h1>
                  ),
                  [BLOCKS.HEADING_2]: (node, children) => (
                    <h2 className="font-Orbitron text-3xl">{children}</h2>
                  ),
                  [BLOCKS.HEADING_3]: (node, children) => (
                    <h3 className="font-Orbitron text-xl">{children}</h3>
                  ),
                  [BLOCKS.EMBEDDED_ASSET]: node => {
                    if (!node.data.target) {
                      return
                    }
                    // console.log("Imagess",node.data.target.gatsbyImageData)
                    const { gatsbyImageData } = node.data.target
                    if (!gatsbyImageData) {
                      // asset is not an image
                      return null
                    }
                    return (
                      <figure className="flex flex-wrap justify-center items-center">
                        <GatsbyImage image={gatsbyImageData} alt="Blog Image" />
                      </figure>
                    )
                  },
                },
              })}
            </div>
          </OnScreen>
        </div>

        <OnScreen classToAdd="slide-in-right">
          <div className="container mb-12 mx-auto px-4 md:mb-24">
            <div className="grid rounded-md shadow-lg">
              <StaticImage
                alt="Success Habits App Home Screen"
                className="space-bg rounded-md"
                layout="fullWidth"
                objectPosition="57% 57%"
                placeholder="blurred"
                quality={100}
                src="../images/index-app-bg.png"
                style={{ gridArea: "1/1" }}
              />

              <div className="grid relative" style={{ gridArea: "1/1" }}>
                <div className="absolute bg-black bg-opacity-30 inset-0 rounded-md" />

                <div className="absolute bottom-0 hidden left-0 z-0 md:block md:w-36 xl:w-full">
                  <StaticImage
                    alt="Green Alien - Happiness Habits"
                    className="w-32"
                    placeholder="blurred"
                    src="../images/alien.png"
                  />
                </div>

                <div className="gap-4 grid grid-cols-1 pt-8 px-8 text-white z-10 md:gap-4 md:grid-cols-2 md:pt-0 md:px-0">
                  <div className="max-w-md mx-auto space-y-8 z-10 md:max-w-lg md:pl-8 md:pt-8 2xl:self-center">
                    <h2 className="font-Orbitron text-2xl lg:text-4xl">
                      The Gamify Your Life App
                    </h2>

                    <p>
                      Learn more about the App geared at gamifying your habits
                      to level up your life.
                    </p>

                    <Button href="/weekly-habit-tracker/" link="GATSBY">
                      Learn More
                    </Button>
                  </div>

                  <div className="flex items-end justify-center sm:max-w-xs sm:mx-auto">
                    <StaticImage
                      alt="Happiness Habits App Home Screen"
                      placeholder="blurred"
                      src="../images/app-mock-index.png"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </OnScreen>
      </div>
    </Layout>
  )
}

export const data = graphql`
  query SuccessHabits {
    allContentfulSuccessHabits {
      edges {
        node {
          headline
          firstSection {
            raw
            references {
              ... on ContentfulAsset {
                contentful_id
                title
                __typename
                gatsbyImageData
                file {
                  fileName
                }
              }
            }
          }

          secondSection {
            raw
            references {
              ... on ContentfulAsset {
                contentful_id
                title
                __typename
                gatsbyImageData
                file {
                  fileName
                }
              }
            }
          }
          thirdSection {
            raw
            references {
              ... on ContentfulAsset {
                contentful_id
                title
                __typename
                gatsbyImageData
                file {
                  fileName
                }
              }
            }
          }
          mission {
            raw
          }
        }
      }
    }
  }
`

export default SuccessHabits
